import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"

export default function BlogPost({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const postImage = getImage(frontmatter.image)

  return (
    <Layout pageTitle={frontmatter.title}>
      <section className="reading blog">
        <h1>{frontmatter.title}</h1>
        <section className="post-meta">
          <span className="date">Posted on {frontmatter.date} </span>
        </section>
        <figure className="post-image">
          <GatsbyImage
            image={postImage}
            alt={frontmatter.title + " cover image"}
          />
          <figcaption>{frontmatter.caption}</figcaption>
        </figure>

        <article dangerouslySetInnerHTML={{ __html: html }} />
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        caption
      }
    }
  }
`
